<template>
  <div>
    <div class="resultsHeading" :class="{'adtBK' : develop == 'adt'}">
      <h2 class="resultsText">Results</h2>
    </div>
    <b-table
      v-if="Array.isArray(playedSeasons)"
      :items="playedSeasons.slice().reverse()"
      :fields="fields"
      :tbody-tr-class="rowClass"
      class="TrRow"
      striped
      :class="{'adtBK' : develop == 'adt'}"
    >
      <template #cell(r1_score)="row">
        <span v-if="row.item.scores_found === 'Y'">
          <span v-if="row.item.r1_score.length">{{ row.item.r1_score }}</span
          ><span v-if="row.item.r2_score.length"> {{ row.item.r2_score }}</span
          ><span v-if="row.item.r3_score.length"> {{ row.item.r3_score }}</span
          ><span v-if="row.item.r4_score.length"> {{ row.item.r4_score }}</span>
          <!-- <span v-if="row.item.r5_score.length"> {{ row.item.r5_score }}</span> -->
        </span>
        <span v-else>{{ row.item.entry_text }}</span>
      </template>
      <template #cell(final_pos)="row">
        <span v-if="row.item.result_found === 'N'"> </span>
        <span v-else>
          {{ row.item.final_pos }}
        </span>
      </template>

      <template #cell(tourn_name)="row">
        <b-link :to="'/tournament/' + row.item.tourn_code + '/' + id" class="linkName">
          {{row.item.tourn_name}}
        </b-link>
      </template>

      <template #cell(winnings)="row">
        <span v-if="row.item.result_found === 'N'"> </span>
        <span v-else>
          {{ row.item.winnings }}
        </span>
      </template>
      <template #cell(t_oom_value)="row">
        <span
          v-if="row.item.tourn_ooms !== undefined && row.item.tourn_ooms !== ''"
        >
          <template
            v-if="
              row.item.tourn_ooms.tourn_oom[0] &&
                row.item.tourn_ooms.tourn_oom[0].t_oom_code == 'OM'
            "
          >
            {{ row.item.tourn_ooms.tourn_oom[0].t_oom_value }}
          </template>
          <template
            v-else-if="
              row.item.tourn_ooms.tourn_oom[0] &&
                row.item.tourn_ooms.tourn_oom[0].t_oom_code == 'IS'
            "
          >
            {{ row.item.tourn_ooms.tourn_oom[1].t_oom_value }}
          </template>
          <template
            v-else-if="
              row.item.tourn_ooms.tourn_oom[1] &&
                row.item.tourn_ooms.tourn_oom[1].t_oom_code == 'IS'
            "
          >
            {{ row.item.tourn_ooms.tourn_oom[1].t_oom_value }}
          </template>
          <template v-else>
            {{ row.item.tourn_ooms.tourn_oom.t_oom_value }}
          </template>
        </span>
        <span v-else>
          N/A
        </span>
      </template>
    </b-table>
    <table v-else>
      <thead>
        <tr class="tableTitle-size">
          <th class="title">
            Date
          </th>
          <th class="title">
            Name
          </th>
          <th class="title hide">
            Pos
          </th>
          <th class="title hide">
            Rounds
          </th>
          <th class="title">
            Score
          </th>
          <th class="title hide">
            Winnings (USD)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="cell">
            {{ playedSeasons.tourn_start }}
          </td>
          <td class="cell tournColour">
            <b-link :to="'/tournament/' + playedSeasons.tourn_code + '/' + id" class="linkName">
              {{ playedSeasons.tourn_name }}
            </b-link>
          </td>
          <template v-if="playedSeasons.scores_found === 'Y'">
            <td class="cell hide">
              {{ playedSeasons.final_pos }}
            </td>
            <td class="cell hide">
              <span>
                <span v-if="playedSeasons.r1_score.length">{{
                  playedSeasons.r1_score
                }}</span
                ><span v-if="playedSeasons.r2_score.length">
                  {{ playedSeasons.r2_score }}</span
                ><span v-if="playedSeasons.r3_score.length">
                  {{ playedSeasons.r3_score }}</span
                ><span v-if="playedSeasons.r4_score.length">
                  {{ playedSeasons.r4_score }}</span
                >
                <!-- <span v-if="playedSeasons.r5_score.length">
                {{ playedSeasons.r5_score }}</span
              > -->
              </span>
            </td>
            <td class="cell">
              {{ playedSeasons.t_score }}
            </td>
            <td class="cell hide">{{ playedSeasons.winnings }}</td>
          </template>
          <template v-else>
            <td class="cell">
              <span>{{ playedSeasons.entry_text }}</span>
            </td>
            <td class="cell">
              <span></span>
            </td>
            <td class="cell">
              <span></span>
            </td>
            <td class="cell">
              <span></span>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "playerresults",
  props: ["playedSeasons", "refno", "year", "id", "develop"],
  data() {
    return {
      noEntry: "noEntry",
      fields: [
        { key: "tourn_start", label: "Date" },
        { key: "tourn_name", label: "Name" },
        { key: "final_pos", label: "Pos" , class: 'hide'},
        { key: "r1_score", label: "Rounds", class: 'hide' },
        { key: "t_score", label: "Score" },
        { key: "winnings", label: "Winnings (USD)", class: 'hide' },
        { key: "t_oom_value", label: "Points" }
      ]
    };
  },
  methods: {
    rowClass(value, type) {
      if (!value || type !== "row") return;
      if (value.scores_found === "N") return "remove";
    }
  }
};
</script>

<style scoped>

.resultsHeading.adtBK {
  background-color: #76c3bd;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > thead {
  background-color: #1c857c!important;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > tbody > tr > td > a.linkName {
  color: #707070!important;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > tbody > tr > td > span{
  color: #707070!important;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > tbody > tr > td > a.linkName:hover {
  color: #1c857c!important;
}
::v-deep table.table.b-table.TrRow.table-striped.adtBK > thead > tr > th  {
  background-color: #1c857c!important;
}
::v-deep .table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff!important;
}
table.b-table.TrRow > tbody > tr:nth-child(odd) {
  background-color: #fff;
}
/* td.tournColour {
  color: #042F62;
  font-weight: 500;
} */
tr:nth-child(odd) {background-color: #fff;}
tr:nth-child(even) {background-color: #7E7E7E;}
a.linkName {
  color: #042F62!important;
  font-weight: 500;
}
a.linkName:hover {
  color: #5897E6!important;
  text-decoration: none;
}
.resultsHeading {
  background-color: #042F62;
  height: 47px;
}
.resultsText {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 4;
  padding-left: 10px;
}
::v-deep tr.remove {
  display: none;
}
.noEntry {
  display: none;
}

::v-deep thead {
  color: white;
  background-color: #042F62!important;
  font-size: 16px;
}

.cell {
  text-align: center;
  max-width: 58px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  margin-bottom: 0px;
  height: 47px;
  position: relative;
  /* top: 6px; */
}
.title {
  text-align: center;
  background-color: #042F62;
  color: white;
  width: 5%;
  font-size: 14px;
  height: 47px;
}
@media only screen and (max-width: 500px) {
  ::v-deep th.hide {
    display: none;
  }
  ::v-deep td.hide {
    display: none;
  }
  .hide {
    display: none;
  }
  .title {
    text-align: left;
    padding-left: 10px;
  }
  .cell {
    text-align: left;
    padding-left: 10px;
  }
}
</style>
